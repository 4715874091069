*{
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Metropolis';
}
@font-face {
  font-family: 'Metropolis';
  src: url('./Metropolis-Regular.otf') format('otf');
}
@font-face {
  font-family: 'MetropolisBold';
  src: url('./Metropolis-Bold.otf') format('otf');
}
@font-face {
  font-family: 'MetropolisXBold';
  src: url('./Metropolis-ExtraBold.otf') format('otf');
  font-weight: bolder;
}
@font-face {
  font-family: 'MetropolisMid';
  src: url('./Metropolis-Medium.otf') format('otf');
}
.business-form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  
}

.header{
  width: 100%;
  background-color: white;
  border-bottom:4px solid #CBBA7F;
  display: flex;
  flex-direction: column;
  align-items:center ;
}

/* .header img{
  
} */

.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  /* padding-bottom: 20px; */
}

.form h1{
  text-align: center;
  font-weight: 1000;
  font-family: 'MetropolisXBold';
  color:black;
}

#asking-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
}

.name-container{
  width:60%;
  overflow: hidden;
  border-radius: 40px;

}

.name-container select{
  width: 100%;
  padding: 18px 10px;
}

.question-container .btn{
  
  display: flex;
  justify-content: center;
  gap:15px;
}
.question-container .btn button{
  align-self:center ;
  border-radius:16px;
  outline: 0;
  border: 0;
  padding: 8px 25px;
  background-color: #113838;
  color: #CBBA7F;
  font-weight: 100;
  margin-top: 10px;
  font-family: 'MetropolisBold';
}



.question-container h2{
  color: #113838;
  font-weight: 1000;
  /* font-size: 1rem; */
  text-align: center;
}

.input-container{
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 4%;
  
}

.input-container label{
  color: #113838;
  font-weight: 500;
}

.form input ,select{
  padding: 15px 7px;
  border-radius: 3px;
  border: 0;
  outline: none;
  background-color: aliceblue;

}
/* textarea{
  
} */

input:focus,textarea:focus{
  border:2px solid #107979;
  background-color: white;
  
}

/* input:not(:placeholder-shown){
  border:2px solid #107979;
  background-color: white;
} */

.submit-button{
  align-self:center ;
  border-radius:40px;
  outline: 0;
  border: 0;
  padding: 8px 25px;
  background-color: #113838;
  color: #CBBA7F;
  font-weight: 100;
  margin-top: 10px;
  font-family: 'MetropolisBold';
}

.card-container{
  background-color: #FEF7EC;
  display: flex;
  flex-direction: column;
  padding: 20px 3% 40px 3%;
  gap:8px;
  border-top: 4px solid #CBBA7F;
}

.card{
  display: flex;
  justify-content: space-between;
  background-color: #113838;
  border-radius: 18px;
  padding: 20px 10px ;
  color: white;
}

.selected{
  scale: 1.05;
  border:2px solid #CBBA7F;
}

.info-container,.price-container{
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.info-container,.price-container{
  gap:3px;
}

.price-container{
  padding-right: 10px;
}

.info-container h2{
  text-align: left;
  font-weight: 400;
  color: #CBBA7F;
}
.info-container span{
  font-weight: 400;
  font-size: small;
}

.price-container .price{
  font-size: 1.8rem;
  font-weight: 400;
}